import { v4 as uuidv4 } from "uuid"
import type { Validation } from "@vuelidate/core"
import { WizardItemStatus } from "@/components/TWizardMenu/IProcessModal"
import getEnv from "./env"

export default class Util {
  /**
   * Get REST API URL
   * @param {string} url part to get full URL
   * @param {number} version Endpoint versions
   * @returns {string} full REST API URL
   */
  static getRestApiUrl(url: string, type: string) {
    let API_ENDPOINT = getEnv("VITE_APP_BACKEND_API") as string

    switch (type) {
      case "cxcore":
        API_ENDPOINT = `${API_ENDPOINT}/${getEnv("VITE_CXCORE_API_PATH")}/${url}`
        break
      case "minifi":
        API_ENDPOINT = `${API_ENDPOINT}/${getEnv("VITE_MINIFI_API_PATH")}/${url}`
        break
      case "sia":
        API_ENDPOINT = `${API_ENDPOINT}/${getEnv("VITE_SIA_API_PATH")}/${url}`
        break
      case "dgk":
        API_ENDPOINT = `${API_ENDPOINT}/govern/${url}`
        break
    }
    return API_ENDPOINT
  }

  public static generateUUID() {
    return uuidv4()
  }

  /**
   * Sort by field function
   * @param {string} field Field to sort for
   * @param {boolean} reverse Reverse sorting direction
   * @param {boolean} ignoreCase case for strings
   * @returns {(a: {[p: string]: {}}, b: {[p: string]: {}}) => (number | number)} Sort function
   */
  static sortByField<T extends object>(field: keyof T, reverse = false, ignoreCase = false) {
    return (a: T, b: T) => {
      const aField = ignoreCase ? String(a[field]).toLowerCase() : a[field]
      const bField = ignoreCase ? String(b[field]).toLowerCase() : b[field]

      return reverse ? Util.compareValues(aField, bField) : Util.compareValues(bField, aField)
    }
  }

  // window scroll to top
  static scrollToTop(): void {
    window.scroll(0, 0)
  }

  private static compareValues(a: any, b: any): number {
    if (a > b) {
      return 1
    }
    if (a < b) {
      return -1
    }
    return 0
  }
}

/**
 * Sort by field function
 * @param {string} field Field to sort for
 * @param {boolean} reverse Reverse sorting direction
 * @param {boolean} ignoreCase case for strings
 * @returns {(a: {[p: string]: {}}, b: {[p: string]: {}}) => (number | number)} Sort function
 */
export function sortByField<T extends object>(field: keyof T, reverse = false, ignoreCase = false) {
  return (a: T, b: T) => {
    const aField = ignoreCase ? String(a[field]).toLowerCase() : a[field]
    const bField = ignoreCase ? String(b[field]).toLowerCase() : b[field]

    return reverse ? compareValues(aField, bField) : compareValues(bField, aField)
  }
}

// sort
export function compareValues(a: {}, b: {}): number {
  if (a > b) {
    return 1
  }
  if (a < b) {
    return -1
  }
  return 0
}

// copy to clipboard
export function copyToClipboard(text: string) {
  void navigator.clipboard.writeText(text)
}

export const validationHasDanger = (validation: { $error: boolean }) => {
  return { "is-danger": validation?.$error }
}

export const validationGetIconName = (error: boolean, value: string) => {
  if (error) {
    return "red-cross"
  }
  if (value !== "") {
    return "success-check"
  }
}

export const getFormWizardStatus = (validator: Validation) => {
  let status
  if (validator.$anyDirty && validator.$invalid) {
    status = WizardItemStatus.INCOMPLETE
  } else if (validator.$anyDirty && !validator.$invalid) {
    status = WizardItemStatus.COMPLETED
  } else {
    status = WizardItemStatus.MISSING
  }
  return status
}

export const getEnvironmentValue = {
  orgName: getEnv("VITE_APP_ORG_NAME") || "",
  orgAdminEmail: getEnv("VITE_APP_ORG_ADMIN_EMAIL") || "",
  dataspaceName: getEnv("VITE_APP_DATASPACE_NAME") || "",
  verifiedBy: getEnv("VITE_APP_VERIFIED_LEGAL_ENTITY_BY") || ""
}

export const makeDspUrl = (url: string, ending: any) => {
  if (!url.endsWith(ending)) {
    return url + ending
  }
  return url
}
